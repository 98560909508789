<template>
  <div class="col-12">
    <div class="row">
      <div class="col-12">
        <input
          id="validatedCustomFile"
          type="file"
          class="custom-file-input"
          accept="image/*"
          @change="setImage"
        >
        <label
          class="custom-file-label"
          for="validatedCustomFile"
        >Selecione uma imagem...</label>
        <div class="invalid-feedback">
          Arquivo inválido
        </div>
      </div>

      <modal
        name="modalImageCrop"
        height="auto"
        :shift-y="0.1"
        :focus-trap="true"
        :adaptive="true"
      >
        <div class="row">
          <div class="col-12 pt-4 text-center">
            <h4>Recorte a imagem</h4>
          </div>
          <div
            v-if="imgSrc"
            class="col-sm-12 col-md-12 col-lg-12 p-4 text-center"
          >
            <vue-cropper
              ref="cropper"
              :src="imgSrc"
              :auto-crop-area="1"
              :aspect-ratio="16 / 9"
            />
            <div class="text-center mt-2">
              <a
                class="btn btn-primary"
                href="#"
                role="button"
                @click.prevent="cropImage"
              >
                Recortar
              </a>
            </div>
          </div>
        </div>
      </modal>

      <div
        v-if="cropImg"
        class="col-sm-12 col-md-12 col-lg-12 mt-2 pl-0 pr-0"
      >
        <div class="row">
          <div class="col-sm-12 col-md-12 col-lg-8 mt-2">
            <div class="mt-2">
              <h6 class="d-block">
                Tamanho original
              </h6>
              <img
                :src="cropImg"
                style="width: auto; max-width: 100%"
                class="img-fluid d-block m-auto"
                alt="Cropped Image"
              >
            </div>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-4 mt-2">
            <div class="fd-app-meus-cursos mt-0">
              <div class="col-sm-12 col-md-12 col-lg-12 fd-app-meus-cursos-list pl-0">
                <div class="fd-app-meus-cursos-list-item mt-2">
                  <h6 class="d-block">
                    Visão do aluno
                  </h6>
                  <div class="fd-app-meus-cursos-list-item-header">
                    <h3>Nome do curso</h3>
                    <p>Detalhes do curso</p>
                  </div>
                  <div class="fd-app-meus-cursos-list-item-body">
                    <img
                      :src="cropImg"
                      style="width: 318px"
                      class="img-fluid d-block m-auto"
                      alt="Cropped Image"
                    >
                    <div class="text-left">
                      <span
                        class="fd-app-meus-cursos-list-item-label fd-app-meus-cursos-list-item-label-danger"
                        style=""
                      >Não Realizado</span>
                    </div>

                    <div class="d-flex mt-4">
                      <ul class="fd-app-meus-cursos-list-item-infolist">
                        <li><strong>Aulas:</strong> X</li>
                        <li><strong>Início:</strong> DD/MM/YYY</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-12 text-center mt-4 pt-4">
            <button class="btn btn-primary">
              SALVAR ALTERAÇÕES
            </button>
          </div>
        </div>
      </div>
      <div
        v-else
        class="crop-placeholder"
      />
    </div>
  </div>
</template>

<script>
import methods from "@/methods";
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
//https://github.com/fengyuanchen/cropperjs#options
//https://www.npmjs.com/package/vue-cropperjs
// https://github.com/Agontuk/vue-cropperjs/tree/master/example

export default {
  components: {
    VueCropper,
  },
  mixins: [methods],
  data() {
    return {
      imgSrc: "",
      cropImg: "",
      data: null,
    };
  },
  methods: {
    cropImage() {
      // get image data for post processing, e.g. upload or setting image src
      this.cropImg = this.$refs.cropper.getCroppedCanvas().toDataURL();
      this.hideModal("modalImageCrop");
    },
    flipX() {
      const dom = this.$refs.flipX;
      let scale = dom.getAttribute("data-scale");
      scale = scale ? -scale : -1;
      this.$refs.cropper.scaleX(scale);
      dom.setAttribute("data-scale", scale);
    },
    flipY() {
      const dom = this.$refs.flipY;
      let scale = dom.getAttribute("data-scale");
      scale = scale ? -scale : -1;
      this.$refs.cropper.scaleY(scale);
      dom.setAttribute("data-scale", scale);
    },
    getCropBoxData() {
      this.data = JSON.stringify(this.$refs.cropper.getCropBoxData(), null, 4);
    },
    getData() {
      this.data = JSON.stringify(this.$refs.cropper.getData(), null, 4);
    },
    move(offsetX, offsetY) {
      this.$refs.cropper.move(offsetX, offsetY);
    },
    reset() {
      this.$refs.cropper.reset();
    },
    rotate(deg) {
      this.$refs.cropper.rotate(deg);
    },
    setCropBoxData() {
      if (!this.data) return;

      this.$refs.cropper.setCropBoxData(JSON.parse(this.data));
    },
    setData() {
      if (!this.data) return;

      this.$refs.cropper.setData(JSON.parse(this.data));
    },
    setImage(e) {
      const file = e.target.files[0];

      if (file.type.indexOf("image/") === -1) {
        alert("Please select an image file");
        return;
      }

      if (file.size / 1024 > 300) {
        alert("Não utilize imagens acima de 300KB");
        return;
      }

      this.showModal("modalImageCrop");

      if (typeof FileReader === "function") {
        const reader = new FileReader();

        reader.onload = (event) => {
          this.imgSrc = event.target.result;
          // rebuild cropperjs with the updated source
          if (this.$refs.cropper) this.$refs.cropper.replace(event.target.result);
        };

        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
    zoom(percent) {
      this.$refs.cropper.relativeZoom(percent);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/*
.content {
  display: flex;
  justify-content: space-between;
}

.cropper-area {
  width: 614px;
}

.actions {
  margin-top: 1rem;
}

.actions a {
  display: inline-block;
  padding: 5px 15px;
  background: #0062cc;
  color: white;
  text-decoration: none;
  border-radius: 3px;
  margin-right: 1rem;
  margin-bottom: 1rem;
}

textarea {
  width: 100%;
  height: 100px;
}

.preview-area {
  width: 307px;
}

.preview-area p {
  font-size: 1.25rem;
  margin: 0;
  margin-bottom: 1rem;
}

.preview-area p:last-of-type {
  margin-top: 1rem;
}

.preview {
  width: 100%;
  height: calc(372px * (9 / 16));
  overflow: hidden;
}

.crop-placeholder {
  width: 100%;
  height: 200px;
  background: #ccc;
}

.cropped-image img {
  max-width: 100%;
}*/
</style>
